import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/home/vincent/Documents/Develop/Web/PersonalWebsite/website/src/components/layout-markdown.tsx";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <p>{`We all like good documentation, but most of us don’t actually put in the effort to write it. Why? Firstly, we’d rather spend our time actually building stuff. But more importantly, for all the time put into it and putting up with various drawing tools, it might be out of date by the time someone needs to look at it. Worse, there’s no way of telling it’s out of date.`}</p>
    <p>{`But let’s break this down even further:`}</p>
    <ul>
      <li parentName="ul">{`The frequency with which documentation is used is way too low for most projects to be worth the effort`}</li>
      <li parentName="ul">{`Most methods like scattering diagrams through Notion don’t scale well`}</li>
      <li parentName="ul">{`You have to manually write this documentation and it’s labor-intensive, skilled work to do so`}</li>
      <li parentName="ul">{`Only developers can derive use from it, so it’s hard to get buy-in from the rest of the organization`}</li>
    </ul>
    <p>{`What if we could`}</p>
    <ul>
      <li parentName="ul">{`make the documentation more frequently useful, also for non-developers?`}</li>
      <li parentName="ul">{`automate part of the work, with manual work only giving you bonus insights?`}</li>
      <li parentName="ul">{`query the results, so you can filter for answers to questions you need solved?`}</li>
      <li parentName="ul">{`produce a single, nested diagram that show how all different pieces of the system connect?`}</li>
    </ul>
    <p>{`That’s what I’m prototyping in my spare time using the Zig programming language. First, it’s a personal exploration in Data Oriented Design, to see performant I can make this to support huge, automatically generated diagrams, merged with human data. (My first test with 1,5 million nodes loads in 5 seconds for now without almost no optimization work.) Also, I did some first experiment performing static analysis on a real-world web code-base and see if I can show which screens in the front-end read and write what kind data from the back-end through which code paths. This would make this a new, customizable way to easily navigate large code-bases.`}</p>
    <p>{`Along the way I’ll be posting about lessons learned in parsing, how simple code can be very performant, debugging, profiling and much more!`}</p>
    <p>{`Keep in touch if this resonates with you and see you soon!`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      